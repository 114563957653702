<template>
  <div class="relative bg-white overflow-hidden">
    <div class="max-w-7xl mx-auto">
      <div
        class="z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32"
      >
        <div>
          <div class="relative pt-6 px-4 sm:px-6 lg:px-8">
            <nav
              class="relative flex items-center sm:h-10 lg:justify-start"
              aria-label="Global"
            >
              <div
                class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0"
              >
                <div class="flex items-center justify-between w-full md:w-auto">
                  <router-link to="/">
                    <span class="sr-only">Mystidia</span>
                    <img
                      class="h-8 w-auto sm:h-10"
                      src="../../assets/mystidia.png"
                    />
                  </router-link>
                </div>
              </div>
            </nav>
          </div>

          <div
            class="absolute top-0 inset-x-0 p-2 transition transform md:hidden"
          >
            <div
              class="
                rounded-lg
                shadow-md
                bg-white
                ring-1 ring-black ring-opacity-5
                overflow-hidden
              "
            >
              <div class="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img
                    class="h-8 w-auto"
                    src="../../assets/mystidia.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <main
          class="
            content-center
            mt-10
            mx-auto
            max-w-7xl
            px-4
            sm:mt-12
            sm:px-6
            md:mt-16
            lg:mt-20
            lg:px-24
            xl:mt-28
          "
        >
          <div class="text-center">
            <h1
              class="
                text-4xl
                tracking-tight
                font-extrabold
                text-gray-900
                sm:text-5xl
                md:text-6xl
              "
            >
              <span class="block xl:inline">Votre email </span>
              <span class="block text-green-400 xl:inline">{{ message }}</span>
            </h1>
            <p
              class="
                mt-3
                text-base text-gray-500
                sm:mt-5
                sm:text-lg
                sm:max-w-xl
                sm:mx-auto
                md:mt-5
                md:text-xl
                text-left
              "
            >
              <span class="block text-red-400 xl:inline">Mystidia</span>
              Vous remercie pour votre confiance.
            </p>
            <div class="mt-5 sm:mt-8 sm:flex justify-center">
              <div class="rounded-md shadow">
                <router-link
                  to="/"
                  class="
                    w-full
                    flex
                    items-center
                    justify-center
                    px-14
                    py-3
                    border border-transparent
                    text-base
                    font-medium
                    rounded-md
                    text-white
                    bg-red-400
                    hover:bg-red-500
                    md:py-4
                    md:text-lg
                    md:px-14
                  "
                >
                  Continuer
                </router-link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: "",
      result: "",
    };
  },
  mounted() {
    this.result = this.$route.query.verify;
    if (this.result == "success") {
      this.message = " à été validée avec succes";
    }
    if (this.result == "already-success") {
      this.message = " est valide";
    }
  },
};
</script>

<style>
</style>